import React from "react"

import Layout from "../components/layout"

import Styles from './404.module.scss'

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <div className={ Styles.notFoundBox }>
      <div className={ Styles.notFoundTitle }>ページが見つかりません</div>
      <div className={ Styles.notFoundDescription }>お探しのページは一時的にアクセスできない状況にあるか、移動または削除された可能性があります。</div>
    </div>
  </Layout>
)

export default NotFoundPage
